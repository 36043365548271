var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{attrs:{"app":"","color":"white","flat":""}},[_c('v-container',{staticClass:"py-0 fill-height"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"mr-10",attrs:{"src":require("@/assets/logo_syngenta.png"),"alt":"Syngenta","width":"170"}})]),_c('vue-progress-bar'),_c('v-list-item-icon',_vm._l((_vm.links),function(link,key){return _c('v-btn',{key:key,attrs:{"text":"","to":{ name: link.to }}},[_c('img',{attrs:{"src":("/assets/images/" + (link.logo)),"width":"50"}}),_vm._v(" "+_vm._s(link.label)+" ")])}),1),_c('v-spacer'),_c('v-responsive',{attrs:{"max-width":"90"}},[_c('v-btn',{attrs:{"text":""}},[(!this.$store.state.isLoggedIn)?_c('router-link',{attrs:{"to":"/login"}},[_vm._v("Login")]):_vm._e(),(this.$store.state.isLoggedIn)?_c('router-link',{attrs:{"to":"/login/logout"}},[_vm._v("Salir")]):_vm._e()],1)],1)],1)],1),_c('v-main',{class:this.$colorfondoapp},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-sheet',{attrs:{"rounded":"lg"}},[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('img',{attrs:{"src":"assets/images/avatar2.png"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(this.$store.state.currentUser.nombre))]),_c('v-list-item-subtitle',[(_vm.isLoggedIn)?_c('div',[_vm._v("Online")]):_vm._e()])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"nav":"","dense":""}},[(_vm.$store.state.currentUser.perfil == 'Administrador')?_c('v-list-group',{attrs:{"no-action":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',{attrs:{"size":"38"}},[_c('img',{attrs:{"src":"/assets/images/2users-team.png"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Usuarios")])],1)]},proxy:true}],null,false,3209191078)},_vm._l((_vm.admins),function(ref,i){
var title = ref[0];
var icon = ref[1];
var to = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":{ name: to },"active-class":"text--accent-3"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1):_vm._e(),(_vm.$store.state.currentUser.perfil == 'Administrador')?_c('v-list-group',{attrs:{"no-action":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',{attrs:{"size":"38"}},[_c('img',{attrs:{"src":"/assets/images/productos.png"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Productos")])],1)]},proxy:true}],null,false,739330163)},_vm._l((_vm.prod),function(ref,i){
var title = ref[0];
var icon = ref[1];
var to = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":{ name: to },"active-class":"text--accent-3"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1):_vm._e(),(_vm.$store.state.currentUser.perfil == 'Administrador')?_c('v-list-group',{attrs:{"no-action":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',{attrs:{"size":"38"}},[_c('img',{attrs:{"src":"/assets/images/cultivo.png"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cultivos")])],1)]},proxy:true}],null,false,1800381952)},_vm._l((_vm.cult),function(ref,i){
var title = ref[0];
var icon = ref[1];
var to = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":{ name: to },"active-class":"text--accent-3"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1):_vm._e(),(_vm.$store.state.currentUser.perfil == 'Administrador')?_c('v-list-group',{attrs:{"no-action":"","color":"orange"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-avatar',{attrs:{"size":"38"}},[_c('img',{attrs:{"src":"/assets/images/2plaga.png"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Soluciones")])],1)]},proxy:true}],null,false,906214722)},_vm._l((_vm.cult),function(ref,i){
var title = ref[0];
var icon = ref[1];
var to = ref[2];
return _c('v-list-item',{key:i,attrs:{"link":"","to":{ name: to },"active-class":"text--accent-3"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}}),_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(icon)}})],1)],1)}),1):_vm._e()],1)],1)],1),_c('v-col',[_c('v-sheet',{attrs:{"min-height":"90vh","rounded":"lg"}},[_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }