import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ encodingType: "aes" });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    currentUser: {},
  },
  getters: {},
  mutations: {
    setLoginUser(state) {
      state.isLoggedIn = true;
    },
    setLogout(state) {
      state.isLoggedIn = false;
      state.currentUser = {};
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("key");
      localStorage.removeItem("my-app");
    },
    setCurrentUser(state, usuario) {
      state.currentUser = usuario;
    },
  },
  actions: {},
  plugins: [
    createPersistedState({
      key: "my-app",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

/**
import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ encodingType: "aes" });

// Create a new store instance.
const store = createStore({
  state() {
    return {
      isLoggedIn: false,
      currentUser: {},
    };
  },
  mutations: {
    setLoginUser(state) {
      state.isLoggedIn = true;
    },
    setLogout(state) {
      state.isLoggedIn = false;
      state.currentUser = {};
      sessionStorage.removeItem("token");
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        storage: window.sessionStorage,
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export default store;
// const app = createApp({
//   /* your root component */
// });

// // Install the store instance as a plugin
// app.use(store);
