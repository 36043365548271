<template>
  <v-app id="inspire">
    <v-app-bar app color="white" flat>
      <v-container class="py-0 fill-height">        
        <router-link to="/" >
        <img
          src="@/assets/logo_syngenta.png"
          alt="Syngenta"
          class="mr-10"
          width="170"
        />
        </router-link>                      

        <vue-progress-bar></vue-progress-bar>
        <!--#####################  MENU BARRA SUPERIOR #################### -->
            <v-list-item-icon>
              <v-btn v-for="(link, key) in links" :key="key" text :to="{ name: link.to }"  >
                      <img :src="`/assets/images/${link.logo}`" width="50" />
                <!-- <router-link :to="{ name: link.to }">{{ link.label }}</router-link> -->
                {{ link.label }}
              </v-btn>
            </v-list-item-icon>

        <v-spacer></v-spacer>

        <v-responsive max-width="90">
          <!-- ############################  login boton ################################# -->
          <v-btn text>           
            <!-- <v-list-item-icon>
                <img src="/assets/images/login.png" width="40" />
            </v-list-item-icon>             -->
                <router-link to="/login" v-if="!this.$store.state.isLoggedIn">Login</router-link>                      
                <router-link to="/login/logout" v-if="this.$store.state.isLoggedIn">Salir</router-link>
          </v-btn>
        </v-responsive>
      </v-container>
    </v-app-bar>
    <!-- ------ color fondo -------- -->
    <v-main :class="this.$colorfondoapp">
      <v-container>
        <v-row>
          <!-- seccion de menu navigation-drawer -->
          <v-col cols="3">
            <v-sheet rounded="lg">
              <v-list-item two-line>
                <v-list-item-avatar>
                  <img src="assets/images/avatar2.png" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{this.$store.state.currentUser.nombre}}</v-list-item-title>
                  <v-list-item-subtitle>                    
                    <div v-if="isLoggedIn">Online</div>                                        
                    </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <!-- seccion del menu costado izquierdo  -->
              <v-list nav dense>
                      <!-- item de usuarios -->
                    <v-list-group                           
                        no-action        
                        color="orange"
                        v-if="$store.state.currentUser.perfil == 'Administrador'"                        
                        >
                          <template v-slot:activator>
                            <v-list-item-avatar size="38">
                              <img src="/assets/images/2users-team.png">
                            </v-list-item-avatar>                        
                            
                            <v-list-item-content>
                              <v-list-item-title>Usuarios</v-list-item-title>
                            </v-list-item-content>
                          </template>

                          <v-list-item
                            v-for="([title, icon, to], i) in admins"
                            :key="i"
                            link            
                            :to="{ name: to }"
                            active-class="text--accent-3"
                          >
                            <v-list-item-title v-text="title"></v-list-item-title>
                            <v-list-item-icon> <v-icon v-text="icon"></v-icon></v-list-item-icon>
                            
                          </v-list-item>
                    </v-list-group>
<!-- item de Productos -->                      
                    <v-list-group                           
                        no-action        
                        color="orange"
                        v-if="$store.state.currentUser.perfil == 'Administrador'"
                        >                                 
                          <template v-slot:activator>
                              <v-list-item-avatar size="38">
                                <img src="/assets/images/productos.png"  >
                              </v-list-item-avatar>                            
                             
                            <v-list-item-content>
                              <v-list-item-title>Productos</v-list-item-title>                              
                            </v-list-item-content>
                          </template>

                          <v-list-item
                            v-for="([title, icon, to], i) in prod"
                            :key="i"
                            link            
                            :to="{ name: to }"
                            active-class="text--accent-3"
                          >
                            <v-list-item-title v-text="title"></v-list-item-title>
                            <v-list-item-icon> <v-icon v-text="icon"></v-icon></v-list-item-icon>
                            
                          </v-list-item>
                    </v-list-group>    

<!-- item de cultivos -->                      
                    <v-list-group                           
                        no-action        
                        color="orange"
                        v-if="$store.state.currentUser.perfil == 'Administrador'"
                        >                                 
                          <template v-slot:activator>
                              <v-list-item-avatar size="38">
                                <img src="/assets/images/cultivo.png"  >
                              </v-list-item-avatar>                            
                             
                            <v-list-item-content>
                              <v-list-item-title>Cultivos</v-list-item-title>                              
                            </v-list-item-content>
                          </template>

                          <v-list-item
                            v-for="([title, icon, to], i) in cult"
                            :key="i"
                            link            
                            :to="{ name: to }"
                            active-class="text--accent-3"
                          >
                            <v-list-item-title v-text="title"></v-list-item-title>
                            <v-list-item-icon> <v-icon v-text="icon"></v-icon></v-list-item-icon>
                            
                          </v-list-item>
                    </v-list-group>                       
<!-- item de soluciones -->                      
                    <v-list-group                           
                        no-action        
                        color="orange"
                        v-if="$store.state.currentUser.perfil == 'Administrador'"
                        >                                 
                          <template v-slot:activator>
                              <v-list-item-avatar size="38">
                                <img src="/assets/images/2plaga.png"  >
                              </v-list-item-avatar>                            
                             
                            <v-list-item-content>
                              <v-list-item-title>Soluciones</v-list-item-title>                              
                            </v-list-item-content>
                          </template>

                          <v-list-item
                            v-for="([title, icon, to], i) in cult"
                            :key="i"
                            link            
                            :to="{ name: to }"
                            active-class="text--accent-3"
                          >
                            <v-list-item-title v-text="title"></v-list-item-title>
                            <v-list-item-icon> <v-icon v-text="icon"></v-icon></v-list-item-icon>
                            
                          </v-list-item>
                    </v-list-group>     
              </v-list>
            </v-sheet>
          </v-col>
          <!-- seccion de main -->
          <v-col>
            <v-sheet min-height="90vh" rounded="lg">
              <v-container fluid>
                <!-- If using vue-router -->
                <router-view></router-view>
              </v-container>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'principal',
  data: () => ({
    links: [
      { label: "Productos", to: "Productos", logo: "productos.png" },
      { label: "Cultivos", to: "Cultivos" , logo: "cultivo.png"},
      { label: "Soluciones", to: "Plagas" , logo: "2plaga.png"},
    ],
    selectedItem: "",    
    current_user: {},
    isLoggedIn:false,
    menu_items: [      
      { text: "Administracion", icon: "mdi-account-multiple", to: "Admin-User",  rol: "Administrador", login: true, sub_menu: "prod"},              
      { text: "Uploads", icon: "mdi-upload",to: "Home",  rol: "Documentador", login: true, sub_menu: "cult"},
      { text: "Backups", icon: "mdi-cloud-upload",to: "Home", rol: "Administrador", login: true, sub_menu: "soluc"},
    ],
    admins: [
      ['Administrar', 'mdi-account-multiple-outline', 'Admin-User'],      
    ],    
    prod: [
      ['Administrar', 'mdi-tire', 'Productos'],      
    ],       
    cult: [
      ['Administrar', 'mdi-tire', 'Cultivos'],      
    ],       
    soluc: [
      ['Administrar', 'mdi-tire', 'Plagas'],      
    ],       
  }),
  mounted(){
    this.current_user = this.$store.state.currentUser;
    this.isLoggedIn   = this.$store.state.isLoggedIn;
    //console.log(`this.current_user`, this.current_user)
  }  
};
</script>

<style lang="scss">
#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
